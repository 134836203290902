<template>
  <app-card v-if="isDataLoaded && !isLoading">
    <TableTitle title="Настройки" />

    <div class="settings-list">
      <div class="settings-item">
        <h3 class="main-subtitle">Восстановление данных</h3>

        <select v-model="selectedBackup">
          <option disabled value="">Выбери бэкап...</option>
          <option v-for="(backup, idx) in backupList" :key="idx">
            {{ backup }}
          </option>
        </select>

        <select v-if="selectedBackup" v-model="selectedBackupCategory">
          <option disabled value="">Выбери категорию...</option>
          <option value="all">Все</option>
          <option
            v-for="(category, idx) in categoriesList"
            :key="idx"
            :value="category"
          >
            {{ getCategoryName(category) }}
          </option>
        </select>

        <app-button
          primary
          :disabled="isBtnDisabled"
          @click="recoverFiles"
          fill
        >
          Восстановить
        </app-button>
      </div>

      <div class="settings-item">
        <h3 class="main-subtitle">Прочее</h3>

        <div class="form-item input">
          <label for="backupTime">Время автоматического сохранения (ч)</label>
          <select id="backupTime" :value="backupTime" ref="backupTime">
            <option v-for="(_, idx) in 24" :value="idx">
              {{ `${idx}:00` }}
            </option>
          </select>
        </div>

        <div class="form-item input">
          <label for="daysOfBackupLife">Дней жизни бэкапов</label>
          <input
            type="number"
            class="text-center"
            id="daysOfBackupLife"
            :value="daysOfBackupLife"
            ref="daysOfBackupLife"
          />
        </div>

        <app-button
          primary
          style="margin-bottom: 0.75rem;"
          fill
          @click="saveSettings"
        >
          Сохранить изменения
        </app-button>

        <app-button warning @click="forceSave" fill>
          Перезаписать текущий бэкап
        </app-button>
      </div>

      <div class="settings-item">
        <h3 class="main-subtitle">Возобновление оплаты</h3>

        <div class="form-item input flex">
          <input
            :class="editFocus"
            type="text"
            id="renewalTime"
            :value="renewalDate"
            ref="renewalDate"
            :disabled="!renewalEdit"
          />

          <app-button
            type="square"
            :primary="renewalEdit"
            @click="saveRenewalDate"
          >
            <EditIcon v-if="!renewalEdit" />
            <SaveIcon v-else />
          </app-button>
        </div>
      </div>
    </div>
  </app-card>
</template>

<script>
import AppCard from '@/components/ui/AppCard'
import TableTitle from '@/components/ui/TableTitle'
import AppButton from '@/components/ui/AppButton'
import EditIcon from '@/components/icons/EditIcon'
import SaveIcon from '@/components/icons/SaveIcon'
import { getCategoryName } from '@/utils/data'

export default {
  name: 'AdminSettings',
  components: {
    AppCard,
    TableTitle,
    AppButton,
    EditIcon,
    SaveIcon
  },
  data() {
    return {
      loading: false,
      selectedBackup: '',
      selectedBackupCategory: '',
      renewalEdit: false
    }
  },
  computed: {
    backupList() {
      return this.$store.getters['backups/list']
    },
    backupTime() {
      return this.$store.getters['settings/backupTime']
    },
    daysOfBackupLife() {
      return this.$store.getters['settings/daysOfBackupLife']
    },
    categoriesList() {
      return this.$store.getters['backups/categoriesList']
    },
    editFocus() {
      return this.renewalEdit ? 'edit-focus' : ''
    },
    isDataLoaded() {
      return this.backupList.length && this.backupTime && this.daysOfBackupLife
    },
    isLoading() {
      return this.loading
    },
    isBtnDisabled() {
      return !this.selectedBackup || !this.selectedBackupCategory
    },
    renewalDate() {
      return this.$store.getters['settings/renewalDate']
    }
  },
  watch: {
    selectedBackup(value) {
      if (value) {
        this.selectedBackupCategory = ''
        this.getCategoriesList(value)
      }
    }
  },
  async created() {
    if (!this.isDataLoaded) {
      this.loading = true
      await this.$store.dispatch('backups/getList')
      await this.$store.dispatch('settings/get')
      this.loading = false
    }
  },
  methods: {
    async getCategoriesList(backupDate) {
      await this.$store.dispatch('backups/getCategoriesList', backupDate)
    },
    getCategoryName,
    async recoverFiles() {
      await this.$store.dispatch('backups/recover', {
        backupDate: this.selectedBackup,
        type: this.selectedBackupCategory
      })
      this.selectedBackupCategory = this.selectedBackup = ''
    },
    forceSave() {
      this.$store.dispatch('backups/forceSave')
    },
    saveSettings() {
      const updateData = {
        backupTime: +this.$refs.backupTime.value,
        daysOfBackupLife: +this.$refs.daysOfBackupLife.value
      }
      this.$store.dispatch('settings/updateSettings', updateData)
    },
    saveRenewalDate() {
      this.renewalEdit = !this.renewalEdit
      if (!this.renewalEdit) {
        this.$store.dispatch('settings/saveRenewalDate', {
          renewalDate: this.$refs.renewalDate.value
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
@import '../styles/variables';

.settings-list {
  display: flex;
  justify-content: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  & > .settings-item:not(:last-child) {
    margin-right: 2rem;
    @media (max-width: 768px) {
      margin-right: 0;
    }
  }
}
.settings-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  & > select {
    margin-bottom: 1rem;
    width: 100%;
  }
  & > * {
    margin-right: auto;
  }
  .main-subtitle {
    margin-bottom: 1.5rem;
    text-align: start;
  }

  & label {
    font-weight: 400;
  }
}

.form-item {
  &.input {
    margin-right: 0;
    width: 100%;
  }
  &.flex {
    display: flex;
  }
  & > input.edit-focus {
    border: 2px solid #0361c0;
    background: darken($dropdown-bg, 9) !important;
  }
}
</style>
