<template>
  <div
    class="card-item card-item--table-title"
    :style="{ 'justify-content': !editable ? 'center' : '' }"
  >
    <div class="card-item card-item--btn-title">
      <h2 class="table-title" :style="{ 'margin-right': editable ? '10px' : '' }">
        {{ title }}
      </h2>

      <app-button
          v-if="editable"
          type="square"
          @click="$emit('edit-title')"
      >
        <EditIcon />
      </app-button>
    </div>

    <app-button
        v-if="editable"
        type="primary"
        style="display:flex; align-items: center"
        @click="$emit('add')"
    >
      <AddIcon style="margin-right: 10px;"/>
      Добавить
    </app-button>
  </div>
</template>

<script>
import AppButton from './AppButton'
import EditIcon from '../icons/EditIcon'
import AddIcon from '../icons/AddIcon'

export default {
  name: 'TableTitle',
  components: {
    AppButton,
    EditIcon,
    AddIcon
  },
  props: {
    editable: {
      required: false,
      default: false,
      type: Boolean
    },
    title: {
      type: String,
      required: true
    }
  }
}
</script>
